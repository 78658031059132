<template>
    <div class="menu-body" v-if="$oidc.userProfile.roles.includes(roles.INDUSTRYENGINEER)">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con" style="padding: 0">
            <div class="even-space-vertical-align">
                <div class="mat-info-con main-tab-act default-font-color" style="font-family: 'BarlowB', sans-serif">
                    Note: Please use
                    <span @click="downloadXlsx()" class="doc-class"> standard template </span> for data uploading.
                    Proper nomenclature for Plant, WorkCenter & Machine name and SAP WC ID are
                    required for data to reflect.
                </div>
            </div>
        </div>
        <div class="main-con">
            <div style="padding: 2rem">
                <div style="display: flex; justify-content: space-between">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Region <span
                                class="require-cl">*</span> </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committed_year"
                            v-model="regionId">
                            <option disabled selected value="0">Select Region</option>
                            <option v-for="region in regionList" :key="region.id" :value="region.regionId">
                                {{ region.regionName }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="month-year" class="form-label select-lable lable-text"> Month, Year <span
                                class="require-cl">*</span></label>
                        <month-picker-input id="month-year" @change="showDate" class="select-text-pd"
                            :default-month="new Date().getMonth() + 1" :default-year="new Date().getFullYear()"
                            :no-default="true"></month-picker-input>
                    </div>

                    <div v-if="activeTab === 'kpi'" style="height: 8rem" class="col-md-4 no-padding upload-button-div">
                        <div class="fileinputs calender-input self-assessment-dummy file-con">
                            <label for="file-input-plant" class="form-label page-heading-dropdown lable-text">
                                Attachments ( Max. allowed 30 MB ) <span class="require-cl">*</span> </label>
                            <input id="file-input-plant" type="file" class="file calender-input file-con"
                                placeholder="NO" @change="onChangeListener($event.target, 'kpi')" />
                            <div class="fakefile">
                                <input id="file-name-KPI" class="fakefileinput" placeholder="No file chosen" />
                                <span class="fakefilespan">Browse</span>
                            </div>
                            <label class="validation-message-style">
                                {{ this.fileErrorMessage }}
                            </label>
                        </div>

                        <div>
                            <div class="edit-button-div">
                                <b-button class="asmt-bt button-sm" style="padding: 0.25rem 1rem" pill
                                    variant="outline-primary" @click="uploadHandler()"
                                    :disabled="!(regionId && fileToUpload)">
                                    Upload
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="activeTab === 'machine'">
                    <div class="action-info-block">
                        <div class="col-md-8 no-padding">
                            <label for="commentSection" class="form-label page-heading-dropdown lable-text">
                                Comments <span class="require-cl">*</span> (max. 500 letters)
                            </label>
                            <textarea class="page-content-regular action-desc" id="commentSection" name="commentSection"
                                rows="4" cols="80" ref="actionDetailTA" placeholder="Leave a comment here"
                                maxlength="500" v-model="commentsUpload"></textarea>
                        </div>
                        <div class="col-md-4 no-padding upload-button-div">
                            <div class="fileinputs calender-input self-assessment-dummy file-con">
                                <label for="file-input-plant" class="form-label page-heading-dropdown lable-text">
                                    Attachments ( Max. allowed 30 MB ) <span class="require-cl">*</span> </label>
                                <input id="file-input-plant" type="file" class="file calender-input file-con"
                                    placeholder="NO" @change="onChangeListener($event.target, 'machine')" />
                                <div class="fakefile">
                                    <input id="file-name" class="fakefileinput" placeholder="No file chosen" />
                                    <span class="fakefilespan">Browse</span>
                                </div>
                                <label class="validation-message-style">
                                    {{ this.fileErrorMessage }}
                                </label>
                            </div>

                            <div>
                                <div class="edit-button-div">
                                    <b-button class="asmt-bt button-sm" style="padding: 0.25rem 1rem" pill
                                        variant="outline-primary" @click="uploadHandler()"
                                        :disabled="!(regionId && commentsUpload && fileToUpload)">
                                        Upload
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="hr" />
            <div v-if="activeTab === 'kpi'">
                <div style="display: flex; justify-content: space-between; padding: 2rem 0 0 0">
                    <div class="main-tab-act default-font-color" style="padding: 0 2rem">Upload History</div>
                    <div class="main-tab-act default-font-color" style="padding: 0 2rem">
                        <img alt="reload" class="reload-img" src="@/assets/refresh.svg" @click="getUploadHistory()" />
                    </div>
                </div>
                <div style="padding: 1rem 0; text-align: left !important">
                    <b-table id="history-table-id" class="gn-table-r" responsive :items="historyItems"
                        :fields="historyFields" :per-page="perPage" :current-page="currentPage">
                        <template #cell(lastupdatedon)="lastUploadedDetail"> {{
                            lastUploadedDetail.value.lastupdatedonDate
                        }} EST </template>
                        <template #cell(comments)="row">
                            <div :id="`comment${row.index}`"></div>
                        </template>
                    </b-table>
                    <div style="display: grid; justify-items: center" v-if="!historyItems.length">
                        <img alt="no data" src="@/assets/No_Data.svg" />
                        <div class="ed-date">No Data Found</div>
                    </div>
                    <div v-if="historyItems && historyItems.length > 5" class="pagination-con">
                        <div class="col-lg-3 col-md-12 col-sm-12 record-info">
                            {{ showingData(historyItems.length) }}
                        </div>
                        <div class="col-lg-7 col-md-12 col-sm-12 pagination-con">
                            <b-pagination class="pagination-align record-info" v-model="currentPage"
                                :total-rows="historyItems.length" :per-page="perPage" aria-controls="my-table"
                                prev-text="Back" next-text="Next" first-text="First" last-text="Last"></b-pagination>
                        </div>
                        <div class="col-lg-2 col-md-12 col-sm-12 record-info text-rt">
                            <div class="per-page-cont record-info">
                                <select v-model="perPage" class="per-page" aria-label="Default select example">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="50">50</option>

                                    <option v-if="historyItems.length > 10 && historyItems.length < 50"
                                        :value="historyItems.length">
                                        {{ historyItems.length }}
                                    </option>
                                </select>
                                Per page
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <MessagePopup :routToBack="false" class="alert" :message="errorMsg" :show="showPopup" :headText="headText" />
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import MessagePopup from "@/components/common/MessagePopup.vue";
import moment from "moment";
import roles from "@/common/constants/roles";
import { MonthPickerInput } from "vue-month-picker";
import { export_json_to_excel } from "@/common/helper";

export default {
    name: "KpiLoadUpload",
    data() {
        return {
            activeTab: "kpi",
            roles: roles,
            showLoader: true,
            regionId: 0,
            monthYear: {
                month: moment(new Date()).format("MMMM"),
                monthIndex: new Date().getMonth() + 1,
                year: new Date().getFullYear()
            },
            commentsUpload: "",
            fileErrorMessage: "",
            fileToUpload: null,
            regionList: [],
            currentPage: 1,
            perPage: 5,
            historyFields: [
                {
                    key: "region",
                    label: "Region",
                    sortable: false
                },
                {
                    key: "filename",
                    label: "File Name",
                    sortable: false
                },
                {
                    key: "lastupdatedby",
                    label: "Uploaded By",
                    sortable: false
                },
                {
                    key: "lastupdatedon",
                    label: "Uploaded On",
                    sortable: false
                },
                {
                    key: "status",
                    label: "Status",
                    sortable: false
                },
                {
                    key: "comments",
                    label: "Remarks",
                    sortable: false
                }
            ],
            historyItems: [],
            errorMsg: "",
            headText: "",
            showPopup: false,
            templateXls: [["PlantName"], ["WorkCenter"], ["MachineName"], ["SapWorkcenter"], ["Year"], ["Month"], ["OEE2BaseLine"], ["OEE2Actual"]],
            templateXlsData: [[]]
        };
    },
    components: {
        Breadcrumb,
        Loader,
        MessagePopup,
        MonthPickerInput
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 4,
            subMenu: 7
        });
        this.$store.dispatch("breadcrumb", {
            title: "Upload KPI File",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Machine Replication", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        if (!this.$oidc.userProfile.roles.includes(this.roles.INDUSTRYENGINEER)) {
            this.$router.push("/");
            return;
        }
        this.getRegions();
        this.getUploadHistory();
    },
    computed: {},
    methods: {
        tabSwitch(pos) {
            switch (pos) {
                case 1:
                    this.activeTab = "machine";
                    break;
                case 2:
                    this.activeTab = "kpi";
                    break;
                default:
                    break;
            }
            this.graphDatas = [];
            this.getCommittedYear();
        },
        getRegions() {
            ApiCalls.httpCallDetails(Api.MACHINEREGIONLIST, "get").then((data) => {
                this.regionList = data.data;
            });
        },
        getUploadHistory() {
            this.showLoader = true;
            this.historyItems = [];
            ApiCalls.httpCallDetails(Api.UPLOADHISTORYKPI, "get").then((data) => {
                this.showLoader = false;
                data.data.forEach((history, index) => {
                    history.comment = history.comment.replaceAll("\n", "<br/>");
                    let comment = document.createElement("div");
                    comment.innerHTML = `<span>${history.comment}</span>`;
                    this.historyItems.push({
                        region: history.regionName,
                        filename: history.fileName,
                        lastupdatedby: history.uploadedBy,
                        lastupdatedon: {
                            lastupdatedonDate: this.estTimeConvert(history.uploadedTime)
                        },
                        status: history.status,
                        comments: comment
                    });
                    setTimeout(() => {
                        document.getElementById(`comment${index}`).appendChild(comment);
                    }, 500);
                });
            });
        },
        estTimeConvert: function (hstDt) {
            let offset = -5.0;
            let dt = new Date(hstDt);
            let neDt = dt.valueOf();
            let serverDate = new Date(neDt + 3600000 * offset);
            let estDtTime = serverDate.toLocaleString("en-US");
            let newdt = moment(new Date(estDtTime)).format("DD-MMM-YYYY hh:mm:ss A");
            return newdt;
        },
        showingData(count) {
            let showData = this.perPage * this.currentPage;
            let showFirstData = showData - this.perPage;
            if (showData > count) {
                showData = count;
            }
            showFirstData = showFirstData + 1;
            return "Showing " + showFirstData + " to " + showData + " of " + count + " records";
        },
        showDate(date) {
            this.monthYear = date;
            console.log(date);
        },
        downloadXlsx() {
            console.log("xls call");
            export_json_to_excel({
                multiHeader: [],
                header: this.templateXls,
                data: this.templateXlsData,
                filename: "KPI_Template",
                merges: [],
                autoWidth: false,
                bookType: "xlsx"
            });
        },
        onChangeListener(value, tab) {
            this.showLoader = true;
            if (value.files[0]) {
                let reader = new FileReader();
                reader.readAsDataURL(value.files[0]);
                reader.onloadend = () => {
                    const filetype = value.files[0].name.split(".")[value.files[0].name.split(".").length - 1];
                    const maxAllowedSize = 30 * 1024 * 1024;
                    if (value.files[0].size > maxAllowedSize) {
                        this.fileErrorMessage = "Please upload file less than 30MB";
                        this.showLoader = false;
                    } else if (!(filetype === "xlsx" || filetype === "xlsm" || filetype === "xls")) {
                        this.fileErrorMessage = "Please upload file of .xlsm , .xlsx , .xls";
                        this.showLoader = false;
                    } else {
                        this.fileErrorMessage = "";
                        document.querySelector("div.self-assessment-dummy #file-name-KPI").value = value.files[0].name;
                        this.showLoader = false;
                        this.fileToUpload = value.files[0];
                    }
                };
            }
            this.showLoader = false;
        },
        uploadHandler() {
            this.showLoader = true;
            this.showPopup = false;
            const bodyContent = new FormData();
            bodyContent.append("RegionId", this.regionId);
            bodyContent.append("File", this.fileToUpload);
            let regionName = this.regionList.filter((item) => item.regionId === this.regionId);
            if (this.activeTab === "machine") {
                bodyContent.append("Comments", this.commentsUpload);
                ApiCalls.httpCallDetails(Api.UPLOADDATAEXCEL, "post", bodyContent).then((data) => {
                    console.log(data);
                    if (data.message && data.success) {
                        this.errorMsg = `Machine Inventory has been successfully uploaded for ${regionName[0].regionName} Region`;
                        this.headText = "";
                        this.showPopup = true;
                    } else if (data.message && !data.success) {
                        this.errorMsg = data.message.trim();
                        this.headText = "Error";
                        this.showPopup = true;
                    }
                    this.commentsUpload = "";
                    this.regionId = 0;
                    document.querySelector("div.self-assessment-dummy #file-name").value = "";
                    this.fileToUpload = null;
                    this.showLoader = false;
                    this.getUploadHistory();
                });
            }
            if (this.activeTab === "kpi") {
                bodyContent.append("Comments", "-");
                bodyContent.append("Month", this.monthYear.monthIndex);
                bodyContent.append("Year", this.monthYear.year);
                ApiCalls.httpCallDetails(Api.UPLOADDATAKPI, "post", bodyContent).then((data) => {
                    console.log(data);
                    if (data.message && data.success) {
                        this.headText = "";
                    } else if (data.message && !data.success) {
                        this.errorMsg = data.message.trim();
                        this.headText = "Error";
                        this.showPopup = true;
                    }
                    this.commentsUpload = "";
                    this.regionId = 0;
                    document.querySelector("div.self-assessment-dummy #file-name-KPI").value = "";
                    this.fileToUpload = null;
                    this.showLoader = false;
                    this.getUploadHistory();
                });
                setTimeout(() => {
                    this.showLoader = false;
                    this.errorMsg = `Upload Successful. \nProcessing of the file is In-progress. It may take some time to reflect in the system.`;
                    this.headText = "";
                    this.showPopup = true;
                    this.getUploadHistory();
                }, 10000);
            }
        }
    }
};
</script>
<style scoped>
/* tabs */

.tab-titles {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-options {
    display: flex;
    margin-top: 2rem;
}

.tab-option-text {
    font: 1.16rem "BarlowR", sans-serif;
    cursor: pointer;
}

.in-action {
    margin-right: 1.688em;
}

.tab-active-option {
    font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
    border-top: 3px #274ba7 solid;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    opacity: 1 !important;
    margin-top: 1rem;
}

.select-dd {
    width: 10%;
}

.button-dd {
    display: inline-flex;
}

.outer-block {
    background-color: #fff;
    border-radius: 6px;
}

.hr {
    position: relative;
    top: -26px;
}

/* tabs */
.plant-dd {
    width: 20.5%;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.reload-img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

div.fileinputs {
    position: relative;
    border: 1px solid #313131;
    border-radius: 4px;
    width: 100%;
}

div.fakefile {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}

.fakefileinput {
    height: 100%;
    width: 80%;
    border: transparent;
    border-right: 1px solid #313131;
    color: #313131;
    font: 1.166rem "BarlowR", sans-serif;
    padding: 5px;
    margin: 2px;
}

.fakefilespan {
    text-align: center;
    width: 20%;
    font: 1.166rem "BarlowSb", sans-serif;
    color: #274ba7;
}

input.file {
    position: relative;
    text-align: right;
    width: 100%;
    /* -moz-opacity:0 ;
	filter:alpha(opacity: 0); */
    opacity: 0;
    z-index: 2;
}

.action-info-block {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
}

.no-padding {
    padding: 0;
}

.lable-text {
    z-index: 2;
}

.upload-button-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.pagination-con {
    display: flex;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.action-desc {
    border: #313131 1px solid;
    border-radius: 4px;
    padding: 1rem;
    width: 90%;
    resize: none;
}

.calender-input {
    height: 52px;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

/* document.querySelector("#history-table-id > thead > tr > th:nth-child(1)") */
#history-table-id {
    border: 0px solid white !important;
    border-top: 1px solid #707070 !important;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1365px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (min-width: 1366px) and (max-width: 1380px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (max-width: 1680px) and (min-width: 1381px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (min-width: 1681px) and (max-width: 1920px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (min-width: 1921px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}
</style>
